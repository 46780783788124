import styled, {DefaultTheme} from 'styled-components';
import {focusStyles, hover, active} from '../../styles/pseudoClasses';

import {Sizes, ButtonTypes} from './index';

const S: Record<string, React.ElementType> = {};

// for v3 only
const fontSize = {
  small: '14px',
  medium: '16px',
  large: '16px',
};

// for v3 only
const lineHeight = {
  small: '20px',
  medium: '24px',
  large: '24px',
};

const size = {
  small: '32px',
  medium: '40px',
  large: '55px',
};

const getMinWidth = (size: Sizes, theme: DefaultTheme) => {
  // min-width = button height / 2
  // height = line height + top padding + bottom padding
  return `${
    (parseInt(lineHeight[size], 10) +
      parseInt(getPadding(size, theme).split(' ')[0], 10)) *
    2
  }px`;
};

const getPadding = (size: Sizes, theme: DefaultTheme) => {
  switch (size) {
    case 'small':
      return `6px ${theme.spacing.MD}`;
    case 'medium':
      return `${theme.spacing.SM} ${theme.spacing.LG}`;
    case 'large':
      return `${theme.spacing.MD} ${theme.spacing.XL}`;
  }
};

const colorsV3 = {
  background: {
    disabled: {
      primary: 'backgroundDisabled',
      'primary inverse': 'backgroundInverseDisabled',
      secondary: 'backgroundTransparent',
      'secondary inverse': 'backgroundTransparent',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
      lightning: 'actionBrand',
      'lightning secondary': 'backgroundBlackTransparent',
    },
    default: {
      primary: 'backgroundInversePrimary',
      'primary inverse': 'backgroundPrimary',
      secondary: 'backgroundTransparent',
      'secondary inverse': 'backgroundTransparent',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
      lightning: 'actionBrand',
      'lightning secondary': 'backgroundBlackTransparent',
    },
  },
  border: {
    disabled: {
      primary: '',
      'primary inverse': '',
      secondary: 'borderSubdued',
      'secondary inverse': 'borderInverseSubdued',
      tertiary: '',
      'tertiary inverse': '',
      lightning: '',
      'lightning secondary': '',
    },
    default: {
      primary: '',
      'primary inverse': '',
      secondary: 'borderPrimary',
      'secondary inverse': 'borderInversePrimary',
      tertiary: '',
      'tertiary inverse': '',
      lightning: '',
      'lightning secondary': 'actionBrand',
    },
  },
  overlay: {
    hover: {
      primary: 'actionInverseHover',
      'primary inverse': 'actionHover',
      secondary: 'actionHover',
      'secondary inverse': 'actionInverseHover',
      tertiary: 'actionHover',
      'tertiary inverse': 'actionInverseHover',
      lightning: 'actionHover',
      'lightning secondary': 'actionInverseHover',
    },
    pressed: {
      primary: 'actionInversePressed',
      'primary inverse': 'actionPressed',
      secondary: 'actionPressed',
      'secondary inverse': 'actionInversePressed',
      tertiary: 'actionPressed',
      'tertiary inverse': 'actionInversePressed',
      lightning: 'actionPressed',
      'lightning secondary': 'actionInversePressed',
    },
  },
  text: {
    disabled: {
      primary: 'textDisabled',
      'primary inverse': 'textInverseDisabled',
      secondary: 'textDisabled',
      'secondary inverse': 'textInverseDisabled',
      tertiary: 'textDisabled',
      'tertiary inverse': 'textInverseDisabled',
      lightning: 'textDefault',
      'lightning secondary': 'textBrand',
    },
    default: {
      primary: 'textInverse',
      'primary inverse': 'textDefault',
      secondary: 'textDefault',
      'secondary inverse': 'textInverse',
      tertiary: 'textDefault',
      'tertiary inverse': 'textInverse',
      lightning: 'textDefault',
      'lightning secondary': 'textBrand',
    },
  },
  focus: {
    primary: 'focus',
    'primary inverse': 'focusInverse',
    secondary: 'focus',
    'secondary inverse': 'focusInverse',
    tertiary: 'focus',
    'tertiary inverse': 'focusInverse',
    lightning: 'focusInverse',
    'lightning secondary': 'focusInverse',
  },
};

const colors = {
  background: {
    disabled: {
      secondary: 'grey50',
      tertiary: 'transparent',
    },
    default: {
      secondary: 'grey500',
      tertiary: 'transparent',
    },
  },
  border: {
    disabled: {
      secondary: '',
      tertiary: 'grey100',
    },
    default: {
      secondary: '',
      tertiary: 'grey800',
    },
  },
  text: {
    disabled: {
      secondary: 'grey200',
      tertiary: 'grey200',
    },
    default: {
      secondary: 'white',
      tertiary: 'grey800',
    },
  },
};

const getColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  position: string,
  v3: boolean
) => {
  if (disabled) {
    if (v3) {
      if (position === 'text') {
        return theme.textColor[colorsV3[position].disabled[type]];
      }
      if (position === 'border') {
        return theme.borderColor[colorsV3[position].disabled[type]];
      }
      if (position === 'background') {
        return theme.backgroundColor[colorsV3[position].disabled[type]];
      }
    }
    return theme.color[colors[position].disabled[type]];
  } else {
    if (v3) {
      if (position === 'text') {
        return theme.textColor[colorsV3[position].default[type]];
      }
      if (position === 'border') {
        return theme.borderColor[colorsV3[position].default[type]];
      }
      if (position === 'background') {
        return theme.backgroundColor[colorsV3[position].default[type]];
      }
    }
    return theme.color[colors[position].default[type]];
  }
};

const getBorder = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  v3: boolean
) => {
  if (type === 'tertiary' || v3) {
    const borderColor = getColor(theme, disabled, type, 'border', v3);
    if (borderColor) {
      return `${theme.borderWidth.default} solid ${borderColor}`;
    }
  }
  return 'none';
};

const getHoverBackgroundColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  isLoading: boolean
) => {
  if (isLoading) {
    return theme.color[colors.background.default[type]];
  } else if (disabled) {
    return theme.color[colors.background.disabled[type]];
  } else {
    if (type === 'secondary') {
      return theme.color.grey600;
    } else {
      return theme.color[colors.background.default[type]];
    }
  }
};

// Needed in case the button is inside of a column flex and width is auto
// Otherwise S.ButtonWrapper by itself will take up the whole width of it's parent and make the focus outline extend that far too
S.ButtonWrapperOuter = styled.div(
  (props: {$width: string; disabled: boolean}) => `
  width: ${props.$width || 'auto'};
  cursor: ${props.disabled ? 'auto' : 'pointer'};

  &:focus {
    outline: none;
  }
`
);

S.ButtonWrapper = styled.div(
  (props: {
    $width: string;
    $type: ButtonTypes;
    $v3: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  display: inline-flex;
  position: relative;
  width: ${props.$width || 'auto'};
  ${
    props.$v3
      ? hover(
          props.theme.backgroundColor[colorsV3.overlay.hover[props.$type]],
          props.disabled
        )
      : ''
  }
  ${
    props.$v3
      ? active(
          props.theme.backgroundColor[colorsV3.overlay.pressed[props.$type]],
          props.disabled
        )
      : ''
  }
`
);

S.FocusBorder = styled.div(
  (props: {theme: DefaultTheme; $type: ButtonTypes; disabled: boolean}) => `
    ${focusStyles(
      props.theme.focusColor[colorsV3.focus[props.$type]],
      props.disabled
    )}
`
);

S.Button = styled.div(
  (props: {
    theme: DefaultTheme;
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
    $isLoading: boolean;
    $size: Sizes;
    $v3: boolean;
    $justifyContent: string;
  }) => `
  height: ${props.$v3 ? 'auto' : size[props.$size]};
  width: ${props.$width || 'auto'};
  min-width: ${props.$v3 ? getMinWidth(props.$size, props.theme) : 'none'};
  padding: ${props.$v3 ? getPadding(props.$size, props.theme) : '0 20px'};
  border-radius: 100px;
  display: inline-flex;
  justify-content: ${props.$justifyContent || 'center'};
  align-items: center;
  box-sizing: border-box;
  background-color: ${getColor(
    props.theme,
    props.disabled,
    props.$type,
    'background',
    props.$v3
  )};
  border: ${getBorder(props.theme, props.disabled, props.$type, props.$v3)}
  };
  position: relative;

  &:hover {
    ${
      !props.$v3
        ? `background-color: ${getHoverBackgroundColor(
            props.theme,
            props.disabled,
            props.$type,
            props.$isLoading
          )}`
        : ''
    };
    border-width: ${
      props.$type === 'tertiary' || props.$v3
        ? props.theme.borderWidth.default
        : '0'
    };
  }
`
);

S.TextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

S.Icon = styled.img(
  (props: {$v3: boolean; theme: DefaultTheme}) => `
  height: ${props.$v3 ? '24px' : '20px'};
  width: ${props.$v3 ? '24px' : '20px'};
  object-fit: contain;
  margin-right: ${props.$v3 ? props.theme.spacing.XS : '10px'};
`
);

S.Text = styled.p(
  (props: {
    $type: ButtonTypes;
    disabled: boolean;
    $isLoading: boolean;
    theme: DefaultTheme;
    $v3: boolean;
    $size: Sizes;
  }) => `
  font-family: ${props.theme.fonts.primary};
  color: ${getColor(
    props.theme,
    props.disabled,
    props.$type,
    'text',
    props.$v3
  )};
  font-size: ${props.$v3 ? fontSize[props.$size] : '16px'};
  font-weight: 500;
  line-height: ${props.$v3 ? lineHeight[props.$size] : '1'};
  opacity: ${props.$isLoading ? 0 : 1};
  white-space: nowrap;
  text-decoration: ${
    props.$v3 &&
    (props.$type === 'tertiary' || props.$type === 'tertiary inverse')
      ? 'underline'
      : 'none'
  };
`
);

S.LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default S;
