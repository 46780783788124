export const focusStyles = (backgroundColor: string, disabled = false) => {
  if (disabled) return '';
  return `
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 4px solid ${backgroundColor};
    border-radius: 100px;
    content: '';
  `;
};

export const focus = (backgroundColor: string, disabled = false) => {
  if (disabled) return '';
  return `
    &:focus {
      outline: none;
      &:before {
        ${focusStyles(backgroundColor)}
      }
    }

  `;
};

export const hover = (backgroundColor: string, disabled = false) => {
  if (disabled) return '';
  return `
    &:hover {
      &:after {
        background: ${backgroundColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100px;
        content: '';
      }
    }
  `;
};

export const active = (backgroundColor: string, disabled = false) => {
  if (disabled) return '';
  return `
    &:active {
      &:after {
        background: ${backgroundColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100px;
        content: '';
      }
    }
  `;
};
