import React, {useState} from 'react';
import Link from 'next/link';
import {ThemeProvider} from 'styled-components';
//Components
import Spinner from '../Spinner';
// Style
import S from './style';
import {theme} from '../../styles/theme';

export type Sizes = 'small' | 'medium' | 'large';
export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'lightning'
  | 'lightning secondary'
  | 'primary inverse'
  | 'secondary inverse'
  | 'tertiary inverse';

export interface ButtonProps {
  text: string;
  type: ButtonTypes;
  disabled?: boolean;
  onClick?: Function;
  width?: string;
  loading?: boolean;
  icon?: string;
  size: Sizes;
  href?: string;
  v3?: boolean;
  tabIndex?: number;
  justifyContent?: string;
}

const Button = ({
  text,
  type,
  disabled = false,
  onClick = () => {
    return;
  },
  width = '',
  loading = false,
  icon = '',
  size,
  href = '',
  v3 = false,
  justifyContent = 'center',
}: ButtonProps) => {
  const [focused, setFocused] = useState(false);

  const handleClick = e => {
    e.stopPropagation();
    if (!onClick) {
      return null;
    }
    return onClick(e);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled && !loading) {
      return onClick(e);
    }
  };

  const getSpinnerType = () => {
    if (
      type === 'primary inverse' ||
      type === 'secondary inverse' ||
      type === 'tertiary inverse' ||
      type === 'lightning secondary'
    ) {
      return 'inverse';
    } else {
      return 'default';
    }
  };

  const getContent = () => {
    return (
      <S.ButtonWrapper
        $width={width}
        $type={type}
        $v3={v3}
        disabled={disabled || loading}
      >
        {v3 && focused && <S.FocusBorder $type={type} $size={size} />}
        <S.Button
          disabled={disabled || loading}
          $isLoading={loading}
          $size={size}
          $v3={v3}
          $type={type}
          $width={width}
          $justifyContent={justifyContent}
        >
          <S.TextWrapper>
            {icon && <S.Icon src={icon} $v3={v3} />}
            <S.Text
              $type={type}
              disabled={disabled || loading}
              $isLoading={loading}
              $v3={v3}
              $size={size}
            >
              {text}
            </S.Text>
            {loading && (
              <S.LoaderWrapper>
                <Spinner size="small" type={getSpinnerType()} />
              </S.LoaderWrapper>
            )}
          </S.TextWrapper>
        </S.Button>
      </S.ButtonWrapper>
    );
  };

  // div with focus properties has to come immediately after <Link />
  if (href) {
    return (
      <ThemeProvider theme={theme}>
        <Link href={href} passHref>
          <S.ButtonWrapperOuter
            $width={width}
            as="a"
            tabIndex={disabled || loading ? -1 : 0}
            disabled={disabled || loading}
            aria-disabled={disabled || loading}
            onFocus={() => {
              if (!disabled && !loading) setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
          >
            {getContent()}
          </S.ButtonWrapperOuter>
        </Link>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <S.ButtonWrapperOuter
        $width={width}
        tabIndex={disabled ? -1 : 0}
        onKeyDown={handleKeyDown}
        onClick={disabled || loading ? null : handleClick}
        disabled={disabled || loading}
        onFocus={() => {
          if (!disabled && !loading) setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        {getContent()}
      </S.ButtonWrapperOuter>
    </ThemeProvider>
  );
};

export default React.memo(Button);
